import { INSCRIPTION } from "../action/inscription.action";

const initialState = {}

export const inscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case INSCRIPTION:
            alert(action.payload.message);
            return action.payload;
        default: 
            return state;
    }
}