import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendcontactform } from '../action/contactform.action';



const Contactform = () => {
    const contactref = useRef();
    const [submitted, setSubmitted] = useState(false);
    const upmessage = useSelector((state) => state.contactformReducer);
    const dispatch = useDispatch()

    const submitcontact = (e) => {
        e.preventDefault();
        const data = {
            nom: contactref.current[0].value,
            prenom: contactref.current[1].value,
            email: contactref.current[2].value,
            telephone: contactref.current[3].value,
            message: contactref.current[4].value,
        }
        if (data.nom && data.email && data.message) {
            dispatch(sendcontactform(data));
            setSubmitted(false);
            contactref.current.reset();
            if (upmessage) {
                alert(upmessage.message);
            }
        } else { 
            setSubmitted(true);
        }
    }

    return (
        <section id="contactback">
            <div className="formcontainer">
                <div className="formtext">
                    <h1>Contactez-nous</h1>
                    <p>Bienvenue à la MAM Tiazaza, votre maison d'assistants maternels dédiée à offrir à votre enfant un environnement chaleureux et sécurisé. Nous serons ravis de répondre à vos questions et de discuter de la manière dont nous pouvons répondre aux besoins uniques de votre enfant. Notre objectif principal est de favoriser le développement global des enfants, en encourageant leur croissance physique, émotionnelle, sociale et cognitive à travers des activités éducatives adaptées à leur âge.</p><br/>
                    <p>Rejoignez notre communauté dévouée d'assistantes maternelles agréées, où votre enfant trouvera un environnement chaleureux, sûr et stimulant pour grandir et s'épanouir. Notre engagement envers le bien-être de chaque enfant est notre priorité absolue. Nous sommes impatients de vous accueillir dans notre communauté chaleureuse et attentionnée. Découvrez la tranquillité d'esprit que nous pouvons vous offrir en rejoignant notre famille de parents et d'enfants.</p><br/>
                    <p>Pour plus d'informations, vous pouvez nous contacter aux coordonnées suivantes :</p>
                    <ul>
                        <li>Adresse : 103 Avenue des Mourlingues 31130 Balma</li>
                        <li>Horaires : Lundi - Vendredi : 7h30 - 19h00 (Fermé le week-end et les jours fériés)</li>
                        <li>Email : mamtiazaza[at]gmail.com</li>
                        <li>Téléphone : 07 81 25 56 40</li>
                    </ul>
                </div>
                <div className="formform">
        
                    <form ref={contactref} onSubmit={(e) => submitcontact(e)}>
                        <label htmlFor="nom">Votre nom *</label>
                        <input type="text" name="nom" id="nom" style={{marginBottom: '0'}} />
                            {submitted && <div style={{fontSize: "12px", color: 'red', marginTop: '0', marginBottom: '10px'}}>Veuillez remplir par votre nom</div>}
                        
                        <label htmlFor="prenom">Votre prénom</label>
                        <input type="text" name="prenom" id="prenom" />
                        
                        <label htmlFor="email">Votre email *</label>
                        <input type="email" name="email" id="email"  style={{marginBottom: '0'}} />
                        {submitted && <div style={{fontSize: "12px", color: 'red', marginTop: '0', marginBottom: '10px'}}>Veuillez remplir par votre email</div>}
                        
                        <label htmlFor="telephone">Votre téléphone</label>
                        <input type="text" name="telephone" id="telephone"/>
                        
                        <textarea name="message" id="message" cols="30" rows="10" placeholder="Votre message, ici" style={{marginBottom: '5px'}} ></textarea>
                        {submitted && <div style={{fontSize: "12px", color: 'red', marginTop: '0', marginBottom: '10px'}}>Veuillez entrer votre message</div>}
                        
                        <input type="submit" value={'Envoyer'} />
                    </form>
                </div>
    
            </div>
    
        </section>)
}

export default Contactform;